// ----------Общие стили------------

h2 {
	font-family: Montserrat;
	font-size: 28px;
	font-weight: bold;
	font-style: normal;
	font-stretch: normal;
	line-height: 1.79;
	letter-spacing: 2.1px;
	text-align: center;
	color: #1c0e06
}

h1, h2 {
	text-transform: uppercase;
}

body {
	font-family: Montserrat;
	font-size: 14px;
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
	line-height: 1.57;
	letter-spacing: 0.4px;
	text-align: center;
	color: #fff;
}

img, a {
	display: block;
}
// ----------Общие стили------------

@import "../libs/sprite/sprite";


// -------------Шапка сайта-----------------

// --------------Лого-----------------
.logo__text1 {
	font-size: 30px;
	font-weight: bold;
	font-style: normal;
	font-stretch: normal;
	line-height: 0.8;
	letter-spacing: 3px;
	text-align: center;
	color: #ffffff;
	margin-bottom: 6px;
	text-transform: uppercase;
}

.logo__text2 {
	font-size: 10px;
	font-weight: 500;
	font-style: normal;
	font-stretch: normal;
	line-height: 1.6;
	letter-spacing: 0.6px;
	text-align: center;
	color: #fffefe;
	text-transform: uppercase;
}

.logo {
	width: 105px;
	height: 105px;
}

.logo__left {
	width: 189px;
	height: 105px;
	background-color: #32363b;
	padding: 23px 10px 20px;
}
// --------------Лого-----------------

.nav__link {
	font-size: 12px;
	font-weight: 500;
	font-style: normal;
	font-stretch: normal;
	letter-spacing: 0.3px;
	color: #222222;
	text-transform: uppercase;
	text-align: center;
}

.nav__link:hover, .contact__link:hover {
	color: #003d76;
}

.nav__item{
	margin: 0 18px;
}

.contact__item, .contact__link {
	font-size: 14px;
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
	letter-spacing: 0.1px;
	text-align: center;
	color: #1c0e06;
}

.contact__item {
	margin: 0 25px;

}

.fa__header {
	color: #003d76;
	font-size: 15px;
}


.first {
	background: url('../img/header.jpg') no-repeat center top;
	background-size: cover;
}

.header2 {
	display: none;
}


.header {
	box-shadow: 0px 3px 30px 0px rgba(0, 0, 0, 0.1);
	background-color: rgba(255,255,255,0.85);
}

.nav__list, .contact__list {
	display: flex;
	justify-content: center;
	align-items: center;
}

.header__wrapper {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.nav {
	margin-bottom: 13px;
}


// -------------Шапка сайта-----------------

 // -------Середина-первого-экрана------------- 

 .center__text1 {
 	margin-bottom: 4px;
 	font-size: 16px;
 	font-weight: 500;
 	font-style: normal;
 	font-stretch: normal;
 	line-height: 2.5;
 	letter-spacing: 0.4px;
 	text-transform: uppercase;
 	text-align: center;
 }

 .center__title{
 	margin-bottom: 13px;
 	font-size: 28px;
 	font-weight: bold;
 	font-style: normal;
 	font-stretch: normal;
 	line-height: 1.43;
 	letter-spacing: 2.1px;
 	text-align: center;
 	text-transform: uppercase;
 }

 .center__text2{
 	margin-bottom: 36px;
 	text-align: center;
 }

 .button {
 	cursor: pointer;
 	width: 260px;
 	height: 60px;
 	background-color: #f5821f;
 	font-size: 15px;
 	font-weight: 500;
 	font-style: normal;
 	font-stretch: normal;
 	line-height: 4;
 	letter-spacing: 0.8px;
 	margin-left:auto;margin-right:auto;
 	color: #fff;
 	display: flex;
 	justify-content: center;
 	align-items: center;
 }

 .center__wrapper{
 	max-width: 563px;
 	margin-left:auto;margin-right:auto;
 }

 .center {
 	padding: 130px 0 144px;
 }

 // -------Середина-первого-экрана------------- 

 // ------------Почему-именно-мы---

 .adv__img1 {
 	@include sprite ($icon1);
 }  
 .adv__img2 {
 	@include sprite ($icon2);
 }  
 .adv__img3 {
 	@include sprite ($icon3);
 }  
 .adv__img4 {
 	@include sprite ($icon4);
 }  

 .adv__info {
 	line-height: 2.14;
 	letter-spacing: 0.1px;
 	text-align: center;
 }

 .adv__item {
 	width: 213px;
 	display: flex;
 	flex-direction: column;
 	align-items: center;
 	justify-content: center;
 }

 .adv {
 	padding: 64px 0 70px;
 	background-color: rgba(50,54,59,0.6);

 }

 .adv__title {
 	color: #fff;
 	margin-bottom: 39px;
 }

 .adv__list {
 	display: flex;
 	justify-content: space-around;
 	align-items: center;
 }

 .adv__img {
 	margin-bottom: 21px;
 }

 .catalog__item {
 	background-color: #1F3D75;
 	display: flex;
 	flex-direction: column;
 	justify-content: center;
 	align-items: center;
 	padding: 90px 20px 36px;

 }




 .catalog__wrapp {
 	padding: 1px;
 	flex-basis: 12.5%; 	
 }

 .catalog__list {
 	display: flex;
 	justify-content: flex-start;
 	flex-wrap: wrap;
 }

 .catalog__img {
 	-moz-transition: all 0.3s ease;
 	-webkit-transition-property: all;
 	-webkit-transition-duration: 0.3s;
 	-webkit-transition-timing-function: ease;
 	margin-bottom: 25px;
 }

 .catalog__img1 {
 	@include sprite ($icon5);
 }

 .catalog__img2 {
 	@include sprite ($icon6);
 }

 .catalog__img3 {
 	@include sprite ($icon7);
 }

 .catalog__img4 {
 	@include sprite ($icon8);
 }

 .catalog__img5 {
 	@include sprite ($icon9);
 }

 .catalog__img6 {
 	@include sprite ($icon10);
 }

 .catalog__img7 {
 	@include sprite ($icon11);
 }

 .catalog__img8 {
 	@include sprite ($icon12);
 }

 .catalog__text {
 	font-size: 16px;
 	font-weight: 500;
 	font-style: normal;
 	font-stretch: normal;
 	line-height: 1.88;
 	letter-spacing: 0.2px;
 	text-align: center;
 	text-transform: uppercase;
 	color: #fff;
 }


 .catalog__title {
 	margin-bottom: 34px;
 	letter-spacing: 2.1px;
 	max-width: 550px;
 	margin-left:auto;margin-right:auto;
 }

 .catalog {
 	padding-top: 67px;
 }

 // ------------Почему-именно-мы---


 // --------Блок-о-нас-------
 .about {
 	color: #fff;
 	padding-top: 52px;
 	padding-bottom: 34px;
 }

 .about__wrapp{
 	position: relative;
 }

 .about__decor {
 	position: absolute;
 	height: 93%;
 	width: 100%;
 	z-index: -1;
 	top: 50%;
 	-webkit-transform: translate(0%,-50%);
 	-ms-transform: translate(0%,-50%);
 	transform: translate(0%,-50%);
 	background-color: #32363b;

 }

 .about__title {
 	color: #fff;
 	margin-bottom: 10px;
 	text-align: left;
 }

 .about__title2 {
 	text-align: right;	
 }

 .about__img {
 	object-fit:cover;
 	width: 100%;
 	height: 100%;
 }

 .abuot__text {
 	max-width: 490px;
 	line-height: 1.6;
 	text-align: left;
 }

 .about__text2 {
 	text-align: right;
 }

 .about__sect {
 	width: 50%;
 }

 .about__wrapper {
 	display: -webkit-flex;
 	display: -moz-flex;
 	display: -ms-flex;
 	display: -o-flex;
 	display: flex;

 }

 .about__sect2 {
 	display: flex;
 	align-items: flex-start;
 	justify-content: flex-end;
 	flex-direction: column;
 	padding: 0 0 45px 45px;
 }

 .about__sect3 {
 	padding: 36px 45px 0 0;
 	display: flex;
 	flex-direction: column;
 	align-items: flex-end;
 	justify-content: flex-start;
 }

 .about__text1 {
 	margin-bottom: 23px;
 }

 .about__img {
 	min-height: 436px;
 	width: 100%;
 }

 .about__img2 {
 	min-height: 393px;
 }

 // --------Блок-о-нас-------

 // ---------Портфолио----------

 .portfolio__item {
 	cursor: pointer;
 	padding: 0;
 	flex-basis: 12.5%;
 	background-color: #fff;
 	margin: 0;
 	padding: 1px;
 }




 .portfolio__img {
 	object-fit: cover;
 	height: 300px;
 	width: 100%;
 }

 .portfolio__list {
 	display: flex;
 	justify-content: flex-start;
 	flex-wrap: wrap;
 }

 .portfolio__title {
 	margin-bottom: 33px;
 }

 .portfolio__wrapper {
 	width: 100%;
 	position: relative;
 }

 .portfolio__decor {
 	position: absolute;
 	top: 0;
 	right: 0;
 	width: 100%;
 	height: 100%;
 	opacity: 1;
 	transition: all 0.3s ease;
 	background-color: rgba(0,0,0,0.7);
 	font-size: 16px;
 	font-weight: 500;
 	font-style: normal;
 	font-stretch: normal;
 	line-height: 1.5;
 	letter-spacing: 0.2px;
 	text-transform: uppercase;
 	display: flex;
 	align-items: center;
 	justify-content: center;
 	color: #fff;
 	    padding-left: 15px;
    padding-right: 15px;
 }

 .portfolio__item:hover .portfolio__decor {
 	opacity: 0;
 }
 // ---------Портфолио----------

 // --------Одна-работа-в-портфолио--------

 .work {
 	display: flex;
 }

 .work__sect {
 	width: 50%;
 }

 .work__sect1 {
 	padding-top: 15px;
 	text-align: left;
 	padding-right: 57px;
 }

 .slider__img {
 	height: 494px;
 	object-fit: cover;
 	width: 100%;
 }
 .test2 {
 	max-width: 586px;
 	width: 586px;
 }

 .single-item {
 	margin-bottom: 40px;
 	box-shadow: 0px 3px 30px 0px rgba(0, 0, 0, 0.1);
 }


 .work__title {
 	margin-bottom: 13px;
 	color: #222222;
 	max-width: 360px;
 	font-size: 16px;
 	text-transform: uppercase;
 	line-height: 1.6;
 	font-weight: 500;
 }

 .work__text {
 	color: #222222;
 	letter-spacing: 0.04em;
 	font-weight: 500;
 	line-height: 1.75;
 }

 .mfp-bottom-bar {
 	display: none;
 }



/* свойства модального окна по умолчанию */
.modal {
}
  /* при отображении модального окно */
  .modal:target {
}
  /* ширина модального окна и его отступы от экрана */
  .modal-dialog {
  	position: relative;
  	width: auto;
  }



  .modal-dialog {
       width: auto; /* для отображения модального окна по центру */
       color: #222222;
       background: #fff;
       margin-left: auto;
       margin-right: auto;
   }

  /* свойства для блока, содержащего контент модального окна */ 
  .modal-content {
  	position: relative;
  	display: -webkit-box;
  	display: -webkit-flex;
  	display: -ms-flexbox;
  	display: flex;
  	-webkit-box-orient: vertical;
  	-webkit-box-direction: normal;
  	-webkit-flex-direction: column;
  	-ms-flex-direction: column;
  	flex-direction: column;
  	background-color: #fff;
  	-webkit-background-clip: padding-box;
  	background-clip: padding-box;
  	outline: 0;
  }
  @media (min-width: 768px) {
  	.modal-content {
  		border-radius: 1px;
  	}
  }
  /* свойства для заголовка модального окна */
  .modal-header {
  	display: -webkit-box;
  	display: -webkit-flex;
  	display: -ms-flexbox;
  	display: flex;
  	-webkit-box-align: center;
  	-webkit-align-items: center;
  	-ms-flex-align: center;
  	align-items: center;
  	-webkit-box-pack: justify;
  	-webkit-justify-content: space-between;
  	-ms-flex-pack: justify;
  	justify-content: space-between;
  	padding: 15px;
  }
  .modal-title {
  	margin-top: 0;
  	margin-bottom: 0;
  	line-height: 1.5;
  	font-size: 1.25rem;
  	font-weight: 500;
  }
  /* свойства для кнопки "Закрыть" */
  .close {
  	position: absolute;
  	font-family: sans-serif;
  	right: 10px;
  	top: 4px;
  	z-index: 10;
  	font-size: 40px;
  	font-weight: 700;
  	line-height: 1;
  	color: #000;
  	text-shadow: 0 1px 0 #fff;
  	opacity: .5;
  	text-decoration: none;
  }
  /* свойства для кнопки "Закрыть" при нахождении её в фокусе или наведении */
  .close:focus, .close:hover {
  	color: #000;
  	text-decoration: none;
  	cursor: pointer;
  	opacity: .75;
  }
  /* свойства для блока, содержащего основное содержимое окна */
  .modal-body {
  	position: relative;
  	-webkit-box-flex: 1;
  	-webkit-flex: 1 1 auto;
  	-ms-flex: 1 1 auto;
  	flex: 1 1 auto;
  	padding: 15px;
  	overflow: auto;
  }

  .sfs {

  }


  // -----------Стили модального окна для партфолио--------------

  .button__portfolio {
  	margin-top: 49px;
  }

  a.portfolio__item:nth-of-type(n+9) {
  	display: none;
  	-moz-transition: all 0.3s ease;
  	-webkit-transition-property: all;
  	-webkit-transition-duration: 0.3s;
  	-webkit-transition-timing-function: ease;
  	opacity: 0;
  }

  .button__portfolio2 {
  	display: none;
  }

  .portfolio__active  {
  	a.portfolio__item:nth-of-type(n+9) {
  		display: block;
  		opacity: 1;
  	}
  	.button__portfolio2 {
  		display: block
  	}
  	.button__portfolio1 {
  		display: none
  	}

  }

// ------Галерея----------

/* padding-bottom and top for image */
.mfp-no-margins img.mfp-img {
	padding: 0;
}
/* position of shadow behind the image */
.mfp-no-margins .mfp-figure:after {
	top: 0;
	bottom: 0;
}
/* padding for main container */
.mfp-no-margins .mfp-container {
	padding: 0;
}


/* 

for zoom animation 
uncomment this part if you haven't added this code anywhere else

*/


.mfp-with-zoom .mfp-container,
.mfp-with-zoom.mfp-bg {
	opacity: 0;
	-webkit-backface-visibility: hidden;
	-webkit-transition: all 0.3s ease-out; 
	-moz-transition: all 0.3s ease-out; 
	-o-transition: all 0.3s ease-out; 
	transition: all 0.3s ease-out;
}

.mfp-with-zoom.mfp-ready .mfp-container {
	opacity: 1;
}
.mfp-with-zoom.mfp-ready.mfp-bg {
	opacity: 0.8;
}

.mfp-with-zoom.mfp-removing .mfp-container, 
.mfp-with-zoom.mfp-removing.mfp-bg {
	opacity: 0;
}


// ------Галерея----------

// -------------Сертификаты-------------

.certificate {
	padding-top: 65px;
	padding-bottom: 80px;
}

.certificate__title {
	margin-bottom: 35px;
}


.certificate__img {
	padding: 0 16px;
}

.certificat__fa {
	&:hover {
		color: #8CBAF6;
	}
	transition: 0.3s;
	position: absolute;
	font-size: 36px;
	margin-top: -18px;
	color: #E3EDF6;
	cursor: pointer;
}

.certificate {
	.certificat__fa {
		top: 50%;
	}
}

.portfolio__block {
  padding-top: 70px;
  padding-bottom: 64px;
}



.portfolio, .pfirst, .portfolio__block {
	.certificat__fa {
		bottom: -50px;
	}

	.fa-arrow-circle-right {
		right: 40%;
	}
	.fa-arrow-circle-left {
		left: 40%;
	}

}

.img_certificate {
	width: 170px;
	height: 238px;
}


.fa-arrow-circle-right {
	right: -60px;
}
.fa-arrow-circle-left {
	left: -60px;
}

// -------------Сертификаты-------------

// --------------Блок-формы----------
.form {
	.container {
		position: relative;
	}
	background-color: #003d76;
	padding: 69px 0 79px;
}


.form__wrapper {
	margin-left: auto;
	margin-right: auto;
	max-width: 390px;
}

.form__send {
	margin-left: auto;
	margin-right: auto;
	max-width: 260px;
}

.form__title {
	font-weight: bold;
	font-style: normal;
	color: #fff;
	margin-bottom: 40px;
	font-stretch: normal;
	line-height: 1.43;
	letter-spacing: 2.1px;
	text-align: center
}

input::-webkit-input-placeholder {
	color: #718AAA !important;
	font-size: 15px;
	font-weight: 500;
	font-style: normal;
	transition: 0.3s;
	font-stretch: normal;
	letter-spacing: 0.8px;
	text-align: left;
}

 input:-moz-placeholder { /* Firefox 18- */
 	color: #718AAA !important;  
 	font-size: 15px;
 	font-weight: 500;
 	font-style: normal;
 	font-stretch: normal;
 	transition: 0.3s;
 	letter-spacing: 0.8px;
 	text-align: left;
 }

  input::-moz-placeholder {  /* Firefox 19+ */
  	color: #718AAA !important;  
  	font-size: 15px;
  	font-weight: 500;
  	font-style: normal;
  	font-stretch: normal;
  	transition: 0.3s;
  	letter-spacing: 0.8px;
  	text-align: left;
  }

  input:-ms-input-placeholder {  
  	color: #718AAA !important;  
  	font-size: 15px;
  	font-weight: 500;
  	font-style: normal;
  	font-stretch: normal;
  	transition: 0.3s;
  	letter-spacing: 0.8px;
  	text-align: left;

  }

  .form__input {
  	font-size: 15px;
  	font-weight: 500;
  	font-style: normal;
  	font-stretch: normal;
  	letter-spacing: 0.8px;
  	text-align: left;
  	color: #fff;
  	display: block;
  	width: 100%;
  	border: 1px solid #7B91B0;
  	padding: 13px 22px;
  	transition: 0.3s;
  	background-color: transparent;
  	margin-bottom: 21px;
  	height: 50px
  }


  .form__botton {
  	line-height: 50px;
  	padding: 0;
  	height: 50px;
  	border-radius: 0;
  	font-size: 15px;
  	font-weight: 500;
  	font-style: normal;
  	font-stretch: normal;
  	letter-spacing: 0.8px;
  	text-align: left;
  	color: #ffffff;
  	width: 100%;
  }

  .form__input:focus {
  	&::-webkit-input-placeholder {
  		color: #fff !important;
  	}

	 &:-moz-placeholder { /* Firefox 18- */
	 	color: #fff !important;  
	 }

	  &::-moz-placeholder {  /* Firefox 19+ */
	  	color: #fff !important;  
	  }

	  &:-ms-input-placeholder {  
	  	color: #fff !important;  
	  }
	  border: 1px solid #fff
	}


	.footer__decor {
		.fa {
			position: absolute;
			top: 1px;
			right: 9px;
			font-size: 32px;
			color: #ffffff;
		}
		&:hover {
			opacity: 1;
		}
		opacity: 0.7;
		right: 15px;
		bottom: -60px;
		position: absolute;
		width: 40px;
		height: 40px;
		border-radius: 40px;
		display: flex;
		justify-content: center;
		align-content: center;
		border: solid 1px #ffffff;
	}


// --------------Блок-формы----------

// ------Блок-футер--------

.footer {
	.contact__item, .contact__link, .nav__link__footer {
		color: #ffffff;
		.nav__wrapper {
			display: flex;
			align-items: center;
			justify-content: flex-start;
			flex-direction: column;
		}
	}
	color: #fff;
	background-color: #1c1e21;
}

.contact__footer {
	margin-bottom: 10px;
}


.logo__left_footer {
	color: #32363b;
    height: 135px;
}

.nav__link__footer, .contact__link_footer {
	color: #fff;
	&:hover {
		color: #003d76;
	}
	
}

.right {
	font-size: 12px;
	font-weight: 300;
	font-style: normal;
	font-stretch: normal;
	letter-spacing: 0.1px;
	text-align: center;
}




// ------Блок-футер--------


// ------------------Страница-Двери----------------------
.dor__center {
	padding: 73px 0 80px;
}


.dor__title {
	margin-bottom: 12px;
	color: #fff;
}

.dor__text {
	max-width: 627px;
	margin-bottom: 44px;
	margin-left: auto;
	margin-right: auto;
}

.dor__item_text {
	color: #1c0e06;
	font-size: 16px;
	text-transform: uppercase;
	letter-spacing: -0.005em;
	padding: 0 25px;
	text-align: center;
}

.dor__item {
	&:hover {

	}
	padding-bottom: 33px;
	-moz-transition: all 0.3s ease;
	-webkit-transition-property: all;
	-webkit-transition-duration: 0.3s;
	-webkit-transition-timing-function: ease;
	height: 100%;
	background-color: #fff;
	box-shadow: 0px 3px 27px 3px rgba(0, 0, 0, 0.2);
}

.dor__img {
    width: 270px;
    height: 270px;
    object-fit: contain;
	transition:  .3s;
	margin-bottom: 15px;
}


.img__wrapper {
	overflow: hidden;
}

.img__wrapper:hover > .dor__img {
	transform: scale(1.2);
}

.catalog__item:hover > .catalog__img {
	transform: scale(1.1);

}

.dor__wrap2 {
	display: none;
}

// ------------------Страница-Двери----------------------

// Стили product page

.product {
	background: none;
}

.pfirst {
	padding-top: 64px;
}

.pfirst__wrapper {
	display: flex;


	.single-item {
		margin-bottom: 61px;
	}

	.slider__img {
		object-fit: fill;
	}


}

.pfirst__sect1 {
	padding-right: 55px;
}

.pfirst__sect1, .pfirst__title2, .pfirst__title {
	text-align: left;
	color: #222;
}

.pfirst__title2, .pfirst__title {
	line-height: 1.5;
}

.pfirst__title2 {
	margin-bottom: 30px;
}

.pfirst__sect {
	width: 50%;
}

.pfirst__text {
	margin-bottom: 22px;
}

.right__test {
	background-color: #fff;
	padding: 3px 0;
	opacity: 1;
	color: #000;
}
// Стили product page

// Блок характеристики

.inform {
background-color: #fff;
	.container {
		position: relative;
	}

	.footer__decor {
		.fa {
			position: absolute;
			top: 1px;
			right: 9px;
			font-size: 32px;
			color: #000;
		}

		&:hover {
			opacity: 1;
		}
		opacity: 0.7;
		right: 15px;
		bottom: -60px;
		position: absolute;
		width: 40px;
		height: 40px;
		border-radius: 40px;
		display: flex;
		justify-content: center;
		align-content: center;
		border: solid 1px #000;
	}

	padding-bottom: 60px;
}

.pfirst__title_s {
	display: none;
}

.inform__list {
	margin-bottom: 20px;
}

.inform__item {
	&:first-child {
		text-transform: uppercase;
		font-size: 16px;
	}
	&:before {
		position: absolute;
		bottom: 0;
		left: 0;
		content: "";
		right: 0;
		width: 100%;
		height: 1px;
		background-color: #e8e8e8;
	}
	position: relative;
	color: #1c0e06;
	    line-height: 1.5;
    padding: 10px 0;
	text-align: left;
	display: flex;
}

.inform__text1 {
		width: 68.5%;

}

.inform__text2 {
	width: 31.5%;
}

// Блок характеристики


/* padding-bottom and top for image */
.mfp-no-margins img.mfp-img {
  padding: 0;
}
/* position of shadow behind the image */
.mfp-no-margins .mfp-figure:after {
  top: 0;
  bottom: 0;
}
/* padding for main container */
.mfp-no-margins .mfp-container {
  padding: 0;
}


/* 

for zoom animation 
uncomment this part if you haven't added this code anywhere else

*/
.portfolio__block {
  
.mfp-iframe-holder .mfp-close, .mfp-image-holder .mfp-close {
    color: #fff;
    right: -6px;
    text-align: right;
    position: fixed;
    padding-right: 6px;
    right: 10px;
    width: 100%;
}
}

.mfp-with-zoom .mfp-container,
.mfp-with-zoom.mfp-bg {
  opacity: 0;
  -webkit-backface-visibility: hidden;
  -webkit-transition: all 0.3s ease-out; 
  -moz-transition: all 0.3s ease-out; 
  -o-transition: all 0.3s ease-out; 
  transition: all 0.3s ease-out;
}

.mfp-with-zoom.mfp-ready .mfp-container {
    opacity: 1;
}
.mfp-with-zoom.mfp-ready.mfp-bg {
    opacity: 0.8;
}

.mfp-with-zoom.mfp-removing .mfp-container, 
.mfp-with-zoom.mfp-removing.mfp-bg {
  opacity: 0;
}


img.mfp-img {
    box-sizing: border-box;
      padding: 0;
    margin: 0 auto;
}


.mfp-iframe-holder .mfp-close, .mfp-image-holder .mfp-close {
    color: #fff;
    right: 10px;
    text-align: right;
    position: fixed;
    padding-right: 6px;
    width: 100%;
}



.footer {
	.logo {
    width: 135px;
    height: 135px;
}
	.logo__left {
    padding: 40px 10px 20px;
}

	.logo__text1 {
		margin-bottom: 10px;
	}

	.logo__left_footer {
		width: 210px;
	}
	.nav {
    margin-bottom: 10px;
}
}

	.dor__item_wrapper {
		margin-bottom: 30px;
	}

	em {
		padding: 0 !important;
	}