// SCSS variables are information about icon's compiled state, stored under its original file name
//
// .icon-home {
//   width: $icon-home-width;
// }
//
// The large array-like variables contain all information about a single icon
// $icon-home: x y offset_x offset_y width height total_width total_height image_path;
//
// At the bottom of this section, we provide information about the spritesheet itself
// $spritesheet: width height image $spritesheet-sprites;
$icon1-name: 'icon1';
$icon1-x: 360px;
$icon1-y: 0px;
$icon1-offset-x: -360px;
$icon1-offset-y: 0px;
$icon1-width: 70px;
$icon1-height: 70px;
$icon1-total-width: 430px;
$icon1-total-height: 360px;
$icon1-image: '../img/sprite.png';
$icon1: (360px, 0px, -360px, 0px, 70px, 70px, 430px, 360px, '../img/sprite.png', 'icon1', );
$icon10-name: 'icon10';
$icon10-x: 120px;
$icon10-y: 120px;
$icon10-offset-x: -120px;
$icon10-offset-y: -120px;
$icon10-width: 120px;
$icon10-height: 120px;
$icon10-total-width: 430px;
$icon10-total-height: 360px;
$icon10-image: '../img/sprite.png';
$icon10: (120px, 120px, -120px, -120px, 120px, 120px, 430px, 360px, '../img/sprite.png', 'icon10', );
$icon11-name: 'icon11';
$icon11-x: 120px;
$icon11-y: 0px;
$icon11-offset-x: -120px;
$icon11-offset-y: 0px;
$icon11-width: 120px;
$icon11-height: 120px;
$icon11-total-width: 430px;
$icon11-total-height: 360px;
$icon11-image: '../img/sprite.png';
$icon11: (120px, 0px, -120px, 0px, 120px, 120px, 430px, 360px, '../img/sprite.png', 'icon11', );
$icon12-name: 'icon12';
$icon12-x: 0px;
$icon12-y: 120px;
$icon12-offset-x: 0px;
$icon12-offset-y: -120px;
$icon12-width: 120px;
$icon12-height: 120px;
$icon12-total-width: 430px;
$icon12-total-height: 360px;
$icon12-image: '../img/sprite.png';
$icon12: (0px, 120px, 0px, -120px, 120px, 120px, 430px, 360px, '../img/sprite.png', 'icon12', );
$icon2-name: 'icon2';
$icon2-x: 360px;
$icon2-y: 70px;
$icon2-offset-x: -360px;
$icon2-offset-y: -70px;
$icon2-width: 70px;
$icon2-height: 70px;
$icon2-total-width: 430px;
$icon2-total-height: 360px;
$icon2-image: '../img/sprite.png';
$icon2: (360px, 70px, -360px, -70px, 70px, 70px, 430px, 360px, '../img/sprite.png', 'icon2', );
$icon3-name: 'icon3';
$icon3-x: 240px;
$icon3-y: 240px;
$icon3-offset-x: -240px;
$icon3-offset-y: -240px;
$icon3-width: 70px;
$icon3-height: 70px;
$icon3-total-width: 430px;
$icon3-total-height: 360px;
$icon3-image: '../img/sprite.png';
$icon3: (240px, 240px, -240px, -240px, 70px, 70px, 430px, 360px, '../img/sprite.png', 'icon3', );
$icon4-name: 'icon4';
$icon4-x: 360px;
$icon4-y: 140px;
$icon4-offset-x: -360px;
$icon4-offset-y: -140px;
$icon4-width: 70px;
$icon4-height: 70px;
$icon4-total-width: 430px;
$icon4-total-height: 360px;
$icon4-image: '../img/sprite.png';
$icon4: (360px, 140px, -360px, -140px, 70px, 70px, 430px, 360px, '../img/sprite.png', 'icon4', );
$icon5-name: 'icon5';
$icon5-x: 240px;
$icon5-y: 0px;
$icon5-offset-x: -240px;
$icon5-offset-y: 0px;
$icon5-width: 120px;
$icon5-height: 120px;
$icon5-total-width: 430px;
$icon5-total-height: 360px;
$icon5-image: '../img/sprite.png';
$icon5: (240px, 0px, -240px, 0px, 120px, 120px, 430px, 360px, '../img/sprite.png', 'icon5', );
$icon6-name: 'icon6';
$icon6-x: 240px;
$icon6-y: 120px;
$icon6-offset-x: -240px;
$icon6-offset-y: -120px;
$icon6-width: 120px;
$icon6-height: 120px;
$icon6-total-width: 430px;
$icon6-total-height: 360px;
$icon6-image: '../img/sprite.png';
$icon6: (240px, 120px, -240px, -120px, 120px, 120px, 430px, 360px, '../img/sprite.png', 'icon6', );
$icon7-name: 'icon7';
$icon7-x: 0px;
$icon7-y: 240px;
$icon7-offset-x: 0px;
$icon7-offset-y: -240px;
$icon7-width: 120px;
$icon7-height: 120px;
$icon7-total-width: 430px;
$icon7-total-height: 360px;
$icon7-image: '../img/sprite.png';
$icon7: (0px, 240px, 0px, -240px, 120px, 120px, 430px, 360px, '../img/sprite.png', 'icon7', );
$icon8-name: 'icon8';
$icon8-x: 120px;
$icon8-y: 240px;
$icon8-offset-x: -120px;
$icon8-offset-y: -240px;
$icon8-width: 120px;
$icon8-height: 120px;
$icon8-total-width: 430px;
$icon8-total-height: 360px;
$icon8-image: '../img/sprite.png';
$icon8: (120px, 240px, -120px, -240px, 120px, 120px, 430px, 360px, '../img/sprite.png', 'icon8', );
$icon9-name: 'icon9';
$icon9-x: 0px;
$icon9-y: 0px;
$icon9-offset-x: 0px;
$icon9-offset-y: 0px;
$icon9-width: 120px;
$icon9-height: 120px;
$icon9-total-width: 430px;
$icon9-total-height: 360px;
$icon9-image: '../img/sprite.png';
$icon9: (0px, 0px, 0px, 0px, 120px, 120px, 430px, 360px, '../img/sprite.png', 'icon9', );
$spritesheet-width: 430px;
$spritesheet-height: 360px;
$spritesheet-image: '../img/sprite.png';
$spritesheet-sprites: ($icon1, $icon10, $icon11, $icon12, $icon2, $icon3, $icon4, $icon5, $icon6, $icon7, $icon8, $icon9, );
$spritesheet: (430px, 360px, '../img/sprite.png', $spritesheet-sprites, );

// The provided mixins are intended to be used with the array-like variables
//
// .icon-home {
//   @include sprite-width($icon-home);
// }
//
// .icon-email {
//   @include sprite($icon-email);
// }
//
// Example usage in HTML:
//
// `display: block` sprite:
// <div class="icon-home"></div>
//
// To change `display` (e.g. `display: inline-block;`), we suggest using a common CSS class:
//
// // CSS
// .icon {
//   display: inline-block;
// }
//
// // HTML
// <i class="icon icon-home"></i>
@mixin sprite-width($sprite) {
  width: nth($sprite, 5);
}

@mixin sprite-height($sprite) {
  height: nth($sprite, 6);
}

@mixin sprite-position($sprite) {
  $sprite-offset-x: nth($sprite, 3);
  $sprite-offset-y: nth($sprite, 4);
  background-position: $sprite-offset-x  $sprite-offset-y;
}

@mixin sprite-image($sprite) {
  $sprite-image: nth($sprite, 9);
  background-image: url(#{$sprite-image});
}

@mixin sprite($sprite) {
  @include sprite-image($sprite);
  @include sprite-position($sprite);
  @include sprite-width($sprite);
  @include sprite-height($sprite);
}

// The `sprites` mixin generates identical output to the CSS template
//   but can be overridden inside of SCSS
//
// @include sprites($spritesheet-sprites);
@mixin sprites($sprites) {
  @each $sprite in $sprites {
    $sprite-name: nth($sprite, 10);
    .#{$sprite-name} {
      @include sprite($sprite);
    }
  }
}
